import {
	IonButton,
	IonCard,
	IonChip,
	IonContent,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonLabel,
	IonModal,
	IonNote,
	IonSpinner,
	IonToolbar,
	useIonToast,
} from "@ionic/react";
import { Trans } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import ShareTenantChip from "components/shareTenant/ShareTenantChip";
import { addOutline, closeOutline, copyOutline, homeOutline, lockOpenOutline, pencilOutline } from "ionicons/icons";
import { FC, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getProfile } from "store/profile/Profile.selectors";
import EditModal from "./EditModal";
import ScopeShareExternToTenant from "components/shareTenant/ScopeShareExternToTenant";
import { getIsFetching, getIsShareExternFetching, getIsShareInternFetching } from "store/profile/address/Address.selectors";
import { Address } from "model/Address";
import ScopeShareInternToTenant from "components/shareTenant/ScopeShareInternToTenant";
import { deleteShareAddressScoped, shareAddressScoped, updateAddress } from "store/profile/address/Address.thunks";
import Loader from "components/loader/Loader";
import { ScopedSharingTenant } from "model/ScopedSharingTenant";
import { newUpsertModel } from "model/UpsertModel";
import ShareTenantSelectModal from "components/shareTenant/ShareTenantSelectModal";
import { CloseFooter } from "components/common/CloseFooter";
import { ShareTenantChipContent } from "components/common/ShareTenantChipContent";

export interface Props {
	isOpen: boolean;
	detailItem: Address;
	onCancel(): void;
}

const DetailModalAddress: FC<Props> = ({ isOpen, detailItem, onCancel }) => {
	const dispatch = useAppDispatch();
	const profile = useSelector(getProfile);
	const isShareInternFetching = useSelector(getIsShareInternFetching);
	const isShareExternFetching = useSelector(getIsShareExternFetching);
	const isFetching = useSelector(getIsFetching);
	const selectRefShare = useRef<HTMLIonSelectElement>(null);
	const modalRef = useRef<HTMLIonModalElement>(null);
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [present] = useIonToast();

	const [shareTenantSelectModalIsOpen, setShareTenantSelectModalIsOpen] = useState(false);
	const [sharedTenants, setSharedTenants] = useState(detailItem.scopedSharingTenants ?? new Array<ScopedSharingTenant>());

	function onShare(finalSharedTenants: ScopedSharingTenant[]) {
		const upsertItem = { ...detailItem };
		upsertItem.scopedSharingTenants = finalSharedTenants;
		dispatch<any>(updateAddress(newUpsertModel<Address>(profile.id, upsertItem)));
		setShareTenantSelectModalIsOpen(false);
	}

	function onShareCancel() {
		setShareTenantSelectModalIsOpen(false);
	}
	function closeModal() {
		onCancel();
		console.log("close modal", isOpen);
	}
	const sharedTenantChips = (entity: Address) => {
		if (entity?.scopedSharingTenants == undefined || entity?.scopedSharingTenants.length == 0) {
			return (
				<>
					<IonChip outline={false} key={`${entity.id}_share_tenantchip`}>
						<IonLabel>Keine Freigaben</IonLabel>
					</IonChip>
				</>
			);
		}

		const result = entity?.scopedSharingTenants?.map((tenant, i) => {
			return <ShareTenantChip scopedSharingTenant={tenant} />;
		});
		return result;
	};
	const presentToast = () => {
		present({
			message: "Erfolgreich in die Zwischenablage kopiert.",
			duration: 1500,
			position: "top",
		});
	};
	return (
		<IonModal key={`${detailItem.id}_detail_m`} color="amber-50" isOpen={isOpen}>
			<EditModal key={"editModal" + detailItem.id} isOpen={editModalOpen} address={detailItem} onCancel={() => setEditModalOpen(false)} />
			<IonModal isOpen={shareTenantSelectModalIsOpen}>
				<ShareTenantSelectModal
					creatorId={detailItem.creator?.id}
					scopedSharingTenants={sharedTenants}
					done={onShare}
					cancel={onShareCancel}
				/>
			</IonModal>
			<IonHeader>
				<IonToolbar color="amber-300">
					<IonButton
						fill="clear"
						slot="start"
						color="amber-900"
						onClick={(e) => {
							e.stopPropagation();
							e.preventDefault();
							closeModal();
						}}
					>
						<IonIcon color="amber-900" icon={closeOutline}></IonIcon>
					</IonButton>

					<IonLabel slot="start">
						<h2>
							<Trans>Detail address</Trans>
						</h2>
					</IonLabel>

					<Loader slot="end" isVisible={isFetching} />
				</IonToolbar>
			</IonHeader>
			<IonContent color="amber-50">
				<div>
					<IonCard>
						<IonItem
							onClick={() => {
								navigator.clipboard.writeText(
									`${detailItem.street} ${detailItem.streetnumber} ${detailItem.postcode} ${detailItem.region}`,
								);
								presentToast();
							}}
							className="ion-margin"
							detail={true}
							detailIcon={copyOutline}
							button={true}
							lines="none"
						>
							<IonIcon icon={homeOutline} slot="start" />
							<IonLabel
								onClick={() => {
									navigator.clipboard.writeText(
										`${detailItem.street} ${detailItem.streetnumber} ${detailItem.postcode} ${detailItem.region}`,
									);
									presentToast();
								}}
								class="ion-text-justify"
							>
								{detailItem.street} {detailItem.streetnumber} <br></br>
								{detailItem.postcode} {detailItem.city}
							</IonLabel>
						</IonItem>
					</IonCard>
				</div>

				<IonItem lines="none">
					<IonChip
						className="ion-padding-end ion-padding-start"
						slot="end"
						disabled={isFetching}
						onClick={(e) => setEditModalOpen(true)}
						outline={false}
						color="primary"
					>
						{isFetching ? (
							<>
								<IonSpinner name="dots" className="ion-margin"></IonSpinner>
							</>
						) : (
							<>
								<IonIcon size="small" color="amber-900" icon={pencilOutline} />
								<IonLabel color="amber-900">Editieren</IonLabel>
							</>
						)}
					</IonChip>
				</IonItem>
				<IonItem
					disabled={isFetching}
					className=" ion-padding-bottom"
					lines="full"
					onClick={(e) => selectRefShare.current?.open(e.nativeEvent)}
				>
					<div>
						<div className="ion-margin-bottom">
							<IonNote>Geteilt mit</IonNote>
						</div>

						{sharedTenantChips(detailItem)}
						<IonChip
							disabled={isFetching}
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								setShareTenantSelectModalIsOpen(true);
							}}
							outline={false}
							color="primary"
						>
							<ShareTenantChipContent isBusy={isFetching} />
						</IonChip>
					</div>
				</IonItem>
				<IonItem disabled={detailItem?.scopedSharingTenants?.length == 0} lines="full" className="ion-padding-bottom">
					<ScopeShareInternToTenant
						scopedSharingTenants={detailItem?.scopedSharingTenants}
						isFetching={isShareInternFetching}
						share={(dtoModal) => dispatch<any>(shareAddressScoped(dtoModal))}
						deleteShare={(dtoModal) => dispatch<any>(deleteShareAddressScoped(dtoModal))}
						userId={profile.id}
						entityId={detailItem.id ?? ""}
					/>
				</IonItem>

				<IonItem disabled={detailItem?.scopedSharingTenants?.length == 0} lines="none">
					<ScopeShareExternToTenant
						scopedSharingTenants={detailItem?.scopedSharingTenants}
						isFetching={isShareExternFetching}
						share={(dtoModal) => dispatch<any>(shareAddressScoped(dtoModal))}
						deleteShare={(dtoModal) => dispatch<any>(deleteShareAddressScoped(dtoModal))}
						userId={profile.id}
						entityId={detailItem.id ?? ""}
					/>
				</IonItem>
			</IonContent>
			<CloseFooter onClick={closeModal} />
		</IonModal>
	);
};

export default DetailModalAddress;
