import axios from "axios";
import { BaseList } from "model/BaseList";
import { BaseListQueryInstance } from "model/BaseListQuery";
import { ContactPerson } from 'model/ContactPerson';
import { DocumentElement } from "model/DocumentElement";
import { Event } from "model/Event";
import { Contract } from "model/events/Contract";
import EventRegistration from "model/events/EventRegistration";
import { Tenant } from "model/Tenant";

class EventService {
	async getList(query: BaseListQueryInstance, userId?: string, tenant?: Tenant, from?: Date, till?: Date): Promise<BaseList<Event>> {
		const params = new URLSearchParams();
		if (tenant) {
			params.append("tenantIds", tenant.id);
		}
		if (from) {
			params.append("from", from.toISOString());
		}
		if (till) {
			params.append("till", till.toISOString());
		}
		if (userId) {
			params.append("userId", userId);
		}
		query.toEntries().forEach(([key, value]) => {
			params.set(key, value as string);
		});

		if (userId) {
			const headers = { userId: userId };
			const res = await axios.get(`${process.env.REACT_APP_API_SEARCH_URL}/api/event`, { params, headers });
			return res.data;
		}

		const res = await axios.get(`${process.env.REACT_APP_API_SEARCH_URL}/api/event`, { params });

		return res.data;
	}

	async get(id: string): Promise<Event> {
		const res = await axios.get<Event>(`${process.env.REACT_APP_API_SEARCH_URL}/api/event/${id}`);
		return res.data;
	}
	async getEventRegistration(eventId: string, personId: string): Promise<EventRegistration> {
		const headers = { userId: personId };

		const res = await axios.get<EventRegistration>(`${process.env.REACT_APP_API_ME_URL}/api/event/${eventId}/registration`, { headers });
		return res.data;
	}
	async getContract(id: string): Promise<Contract> {
		const res = await axios.get<Contract>(`${process.env.REACT_APP_API_BASE_URL}/api/event/${id}/contract`);
		return res.data;
	}
	async getParticipationInfo(id: string, tenantid: string, userId: string) {
		const res = await axios.get(
			`${process.env.REACT_APP_API_BASE_URL}/api/association/${tenantid}/event/${id}/eventparticipant/${userId}`, ///fulldetail`,
			{},
		);
		return res.data;
	}
	async getDocuments(id: string, tenantId: string): Promise<BaseList<DocumentElement>> {
		const res = await axios.get<BaseList<DocumentElement>>(
			`${process.env.REACT_APP_API_BASE_URL}/api/association/${tenantId}/event/${id}/documentupload`,
		);
		return res.data;
	}
	async withdrawParticipation(userId: string, eventId: string): Promise<boolean> {

		const res = await axios.post(
			`${process.env.REACT_APP_API_ME_URL}/api/event/${eventId}/registration/cancel`,
			{ SendNotification: true },
			{ headers: { userId: userId } })
			;
		return res.status === 200;
	}
	async getContactPersons(id: string, tenantId: string): Promise<BaseList<ContactPerson>> {
		const res = await axios.get<BaseList<ContactPerson>>(
			`${process.env.REACT_APP_API_BASE_URL}/api/association/${tenantId}/event/${id}/contactperson`,
		);
		return res.data;
	}
}
const eventService = new EventService();
export default eventService;

