import "./Events.css";

import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { removeClass } from "@syncfusion/ej2-base";
import { DataManager, Query, WebApiAdaptor } from "@syncfusion/ej2-data";
import {
	Agenda,
	EventClickArgs,
	EventRenderedArgs,
	Inject,
	Month,
	PopupOpenEventArgs,
	RenderCellEventArgs,
	ScheduleComponent,
	TimelineYear,
	View,
	ViewDirective,
	ViewsDirective,
	Week,
} from "@syncfusion/ej2-react-schedule";
import { useAppDispatch } from "app/hooks";
import Page from "components/page/Page";
import { Event } from "model/Event";
import { createRef, useState, VFC } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getProfile, getSelectedTenant } from "store/profile/Profile.selectors";
import "./EventsCalendarPage.css";
const EventsCalendarPage: VFC = () => {
	const dispatch = useAppDispatch();

	const [visible, setVisible] = useState(false);
	// const [view, setView] = useState(EventsView.List);
	const history = useHistory();
	const selectedTenant = useSelector(getSelectedTenant);
	const userId = useSelector(getProfile)?.id;
	const scheduleObj = createRef<ScheduleComponent>();
	const onViewEnter = () => {
		setVisible(true);
	};

	const onViewLeave = () => {
		setVisible(false);
	};

	const onRenderCell = (args: RenderCellEventArgs) => {
		if (args.elementType === "dateHeader" || args.elementType === "monthCells") {
			removeClass(args.element.childNodes, "e-navigate");
		}
		if (args.elementType === "dateHeader" || args.elementType === "monthCells") {
			removeClass(args.element.childNodes, "e-add");
			removeClass(args.element.childNodes, "e-work-week");
			removeClass(args.element.childNodes, "e-today");
			removeClass(args.element.childNodes, "e-agenda");
		}
	};
	const dataManager = new DataManager({
		url: `${process.env.REACT_APP_API_SEARCH_URL}/api`,
		adaptor: new WebApiAdaptor(),
	});
	const onDataBinding = (e: any) => {
		const items = e.result.items as Array<Event>;
		const scheduleData = [];
		if (items.length > 0) {
			for (let i = 0; i < items.length; i++) {
				const event = items[i];
				scheduleData.push({
					Id: event.id,
					Subject: event.name,
					StartTime: event.startTime,
					EndTime: event.endTime,
					//   IsAllDay: !event.start.dateTime
				});
			}
		}
		e.result = scheduleData;
	};
	//params.append("tenantIds", tenant.id);

	//TODO: If TenantId set , add TenantId to parameter
	const dataQuery = new Query().from("event").addParams("page", "1").addParams("pageSize", "800").addParams("tenantIds", selectedTenant?.id);

	const popUpOpen = (args: PopupOpenEventArgs) => {
		args.cancel = true;
		return;
	};

	interface syncfusionEvent {
		Id: string;
		Guid: string;
	}

	const eventClicked = (args: EventClickArgs) => {
		const ev = args?.event as syncfusionEvent;
		if (ev == undefined) {
			return;
		} else {
			history.push(`/events/${ev.Id}`);
		}
	};
	const eventRenderd = (args: EventRenderedArgs) => {
		if (scheduleObj?.current == null) {
			return;
		}
		applyCategoryColor(args, scheduleObj.current.currentView);
	};
	const applyCategoryColor = (args: EventRenderedArgs, currentView: View) => {
		//const categoryColor = args.data.CategoryColor;
		const categoryColor = "#FCD34D";
		const fontColor = "#451A03";

		if (!args.element || !categoryColor) {
			return;
		}
		// CurrentView describes to look at the table, Month/Year/Week/Agenda
		if (currentView === "Agenda") {
			//	args.element.firstChild.style.borderLeftColor = categoryColor;
		} else {
			args.element.style.backgroundColor = categoryColor;
			args.element.style.color = fontColor;
		}
	};
	return (
		<Page title={i18n._(t`Events`)} onViewEnter={onViewEnter} onViewLeave={onViewLeave} tabBarHidden={false}>
			<ScheduleComponent
				eventSettings={{
					dataSource: dataManager,
					query: dataQuery,
					enableTooltip: false,
					allowAdding: false,
					allowDeleting: false,
					allowEditing: false,
				}}
				cssClass="e-customClass"
				ref={scheduleObj}
				currentView="Month"
				dataBinding={onDataBinding}
				firstDayOfWeek={1}
				allowInline={false}
				eventClick={(args: EventClickArgs) => eventClicked(args)}
				allowMultiCellSelection={false}
				allowDragAndDrop={false}
				rowAutoHeight={true}
				allowResizing={false}
				allowSwiping={true}
				popupOpen={(arg) => popUpOpen(arg)}
				agendaDaysCount={120}
				allowMultiRowSelection={false}
				eventRendered={(args: EventRenderedArgs) => eventRenderd(args)}
				renderCell={(arg: RenderCellEventArgs) => onRenderCell(arg)}
			>
				<ViewsDirective>
					<ViewDirective option="Week" interval={2} />
					<ViewDirective option="Month" />

					<ViewDirective option="Agenda" allowVirtualScrolling={true} />

					<ViewDirective option="TimelineYear" orientation="Vertical" />
				</ViewsDirective>
				<Inject services={[Month, Week, Agenda, TimelineYear]} />
			</ScheduleComponent>
		</Page>
	);
};

export default EventsCalendarPage;
