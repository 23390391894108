import {
	IonAvatar,
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonCardTitle,
	IonContent,
	IonFooter,
	IonHeader,
	IonIcon,
	IonItem,
	IonLabel,
	IonList,
	IonModal,
	IonProgressBar,
	IonTitle,
	IonToolbar,
	useIonToast,
} from "@ionic/react";
import axios from "axios";
import { CloseFooter } from "components/common/CloseFooter";
import { scopeTypeToQuery } from "helper/QueryHelper";
import { callOutline, closeOutline, copyOutline, mailOutline } from "ionicons/icons";
import { BaseList } from "model/BaseList";
import { ContactPerson } from "model/ContactPerson";
import { Mail } from "model/Mail";
import { Phone } from "model/Phone";
import { ScopeType } from "model/ScopeType";
import { useEffect, useState, FC } from "react";
import { useSelector } from "react-redux";
import { getProfile } from "store/profile/Profile.selectors";

interface ContactPersonCardModalProps {
	isOpen: boolean;
	onDismiss: () => void;
	tenantId: string;
	contactPerson: ContactPerson | undefined;
	scopeType: ScopeType;
}

const ContactPersonCardModal: FC<ContactPersonCardModalProps> = ({ isOpen, onDismiss, tenantId, contactPerson, scopeType }) => {
	const [isBusy, setIsBuy] = useState(false);
	const profile = useSelector(getProfile);
	const [phone, setPhone] = useState<string | undefined>("");
	const [mail, setMail] = useState<string | undefined>("");
	const [present] = useIonToast();

	useEffect(() => {
		if (!isOpen || contactPerson == undefined) {
			return;
		}
		const fetchData = async () => {
			setIsBuy(true);
			await getPhone(tenantId);
			await getMail(tenantId);
		};
		fetchData().then(() => setIsBuy(false));
	}, [isOpen]);

	const presentToast = () => {
		present({
			message: "Erfolgreich in die Zwischenablage kopiert.",
			duration: 1500,
			position: "top",
		});
	};
	const getPhone = async (tenantId: string) => {
		try {
			const res = await axios.get<BaseList<Phone>>(
				`${process.env.REACT_APP_API_SEARCH_URL}/api/phone/person/${contactPerson?.id}?tenantId=${tenantId}${scopeTypeToQuery(scopeType)}`,
				{
					headers: { userId: profile?.id },
				},
			);
			if (res.status == 200) {
				if (res.data.items.length > 0) {
					setPhone("+" + res.data.items[0]?.phoneNumberCountryCode + res.data.items[0]?.phoneNumber);
				} else {
					setPhone(undefined);
				}
			} else {
				setPhone(undefined);
				console.log("Error: " + res.status);
			}
		} catch (error) {
			console.log(error);
			setPhone(undefined);
		}
	};
	const getMail = async (tenantId: string) => {
		try {
			const res = await axios.get<BaseList<Mail>>(
				`${process.env.REACT_APP_API_SEARCH_URL}/api/mail/person/${contactPerson?.id}?tenantId=${tenantId}${scopeTypeToQuery(scopeType)}`,
				{
					headers: { userId: profile?.id },
				},
			);
			if (res.status == 200) {
				console.log(res.data);
				if (res.data.items.length > 0) {
					setMail(res.data.items[0]?.mailAddress ?? "");
				} else {
					setMail(undefined);
				}
			} else {
				setMail(undefined);
			}
		} catch (error) {
			setMail(undefined);
			console.log(error);
		}
	};

	return (
		<IonModal key={`${contactPerson?.id}_modal`} isOpen={isOpen} showBackdrop={true} backdropDismiss={true} onDidDismiss={(e) => onDismiss()}>
			<IonHeader>
				<IonToolbar color="amber-300">
					<IonButton
						slot="start"
						fill="clear"
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							onDismiss();
						}}
					>
						<IonIcon color="medium" icon={closeOutline}></IonIcon>
					</IonButton>
					<IonLabel slot="start">
						<h2>
							{contactPerson?.firstName} {contactPerson?.lastName}
						</h2>
					</IonLabel>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				{isBusy ? (
					<>
						<IonProgressBar type="indeterminate"></IonProgressBar>
					</>
				) : (
					<>
						<IonCard>
							<IonCardHeader>
								<IonCardSubtitle>Email</IonCardSubtitle>
							</IonCardHeader>
							<IonCardContent>
								<IonList>
									<IonItem
										detailIcon={copyOutline}
										onClick={() => {
											navigator.clipboard.writeText(mail ?? "");
											presentToast();
										}}
										button={true}
										lines="none"
									>
										{mail == undefined ? (
											"Keine Email Adresse"
										) : (
											<>
												<IonIcon
													icon={mailOutline}
													onClick={(e) => {
														window.location.href = "mailto:" + mail;
														e.preventDefault();
													}}
													slot="start"
												/>
												<IonLabel slot="start">{mail}</IonLabel>
											</>
										)}
									</IonItem>
								</IonList>
							</IonCardContent>
						</IonCard>
						<IonCard>
							<IonCardHeader>
								<IonCardSubtitle>Telefon</IonCardSubtitle>
							</IonCardHeader>
							<IonCardContent>
								<IonList>
									<IonItem
										detailIcon={copyOutline}
										onClick={() => {
											navigator.clipboard.writeText(phone ?? "");
											presentToast();
										}}
										button={true}
										lines="none"
									>
										{mail == undefined ? (
											"Keine Telefonnummer"
										) : (
											<>
												<IonIcon
													icon={callOutline}
													slot="start"
													onClick={(e) => {
														window.location.href = "tel:" + phone;
														e.preventDefault();
													}}
												/>

												<IonLabel slot="start">{phone}</IonLabel>
											</>
										)}
									</IonItem>
								</IonList>
							</IonCardContent>
						</IonCard>
					</>
				)}
			</IonContent>
			<CloseFooter onClick={onDismiss} />
		</IonModal>
	);
};

export default ContactPersonCardModal;
