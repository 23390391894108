import "./style.scss";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import { Toast } from "@capacitor/toast";
import {
	IonAvatar,
	IonButton,
	IonButtons,
	IonCol,
	IonContent,
	IonFooter,
	IonGrid,
	IonHeader,
	IonIcon,
	IonImg,
	IonItem,
	IonItemDivider,
	IonLabel,
	IonLoading,
	IonModal,
	IonRouterLink,
	IonRow,
	IonTitle,
	IonToolbar,
	useIonAlert,
} from "@ionic/react";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import axios from "axios";
import { arrowBackOutline } from "ionicons/icons";
import { Event } from "model/Event";
import { ClubRequired } from "model/events/ClubRequired";
import { EventProgressType } from "model/events/EventProgressType";
import EventRegistration, { newEventRegistrationByEvent } from "model/events/EventRegistration";
import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchEventParticipant, fetchEventRegistrationById } from "store/event/Event.thunks";
import { getProfile } from "store/profile/Profile.selectors";

import EventAddressPart from "./parts/EventAddressPart";
import EventAGBPart from "./parts/EventAGBPart";
import EventClubPart from "./parts/EventClubPart";
import EventIBANPart from "./parts/EventIBANPart";
import EventMailPart from "./parts/EventMailPart";
import EventNotePart from "./parts/EventNotePart";
import EventPhonePart from "./parts/EventPhonePart";
import EventPricePart from "./parts/EventPricePart";
import EventStartPart from "./parts/EventStartPart";
import EventStartProfilePart from "./parts/EventStartProfilePart";
import EventTakePart from "./parts/EventTakePart";
import { createSelectedAdditionalService, SelectedAdditionalService } from "model/payment/SelectedAdditionalService";
import { PrivacyVerfiyEventApprovement } from "components/privacy/PrivacyVerfiyEventApprovement";

interface IEventParticipateStartProps {
	event: Event;
	isOpen: boolean;
	dismiss: () => void;
}

function validateRegistration(event: Event, eventRegistration: EventRegistration): boolean {
	if (!eventRegistration?.eventProgressType) {
		return false;
	}
	if (!eventRegistration?.emailId) {
		return false;
	}
	if (event.addressRequired && !eventRegistration?.addressId) {
		return false;
	}
	if (event.addressRequired && !eventRegistration?.phoneId) {
		return false;
	}
	if (event.ibanRequired && !eventRegistration?.bankAccountId) {
		return false;
	}
	if (event.paymentRequired && (!eventRegistration?.addressId || !eventRegistration?.bankAccountId)) {
		return false;
	}
	if (
		event.contract?.necessaryApprovals &&
		eventRegistration?.approvals &&
		event.contract?.necessaryApprovals?.length !== eventRegistration?.approvals?.length
	) {
		return false;
	}
	if (event.paymentRequired && !eventRegistration?.priceId) {
		if (eventRegistration.eventProgressType == EventProgressType.Waiting) {
			return true;
		}
		return false;
	}
	if (event.clubRequired == ClubRequired.Required) {
		if (eventRegistration?.tenantId || eventRegistration?.tenantName) {
			return true;
		}
		return false;
	}
	return true;
}
const EventParticipateStart: React.FunctionComponent<IEventParticipateStartProps> = ({ event, isOpen, dismiss }) => {
	const dispatch = useAppDispatch();
	const [eventRegistration, setEventRegistration] = useState<EventRegistration | undefined>(undefined);
	const [canExecute, setCanExecute] = useState(false);
	const [presentAlert] = useIonAlert();
	const [privacyChecked, setPrivacyChecked] = useState(false);
	const profile = useSelector(getProfile);
	const [isBusy, setIsBuy] = useState(false);
	//console.log(event);
	const updateEventRegistration = (eventRegistration: EventRegistration) => {
		setEventRegistration(JSON.parse(JSON.stringify(eventRegistration)));
		setCanExecute(validateRegistration(event, eventRegistration));
	};

	useEffect(() => {
		if (event?.id && !eventRegistration && profile) {
			setEventRegistration(newEventRegistrationByEvent(event.id, profile.id, event.contractId));
		}

		if (event && eventRegistration) {
			setCanExecute(validateRegistration(event, eventRegistration));
		}
	}, [event, profile, eventRegistration]);

	useEffect(() => {
		if (event && eventRegistration) {
			setCanExecute(validateRegistration(event, eventRegistration));
		}
	}, [event, eventRegistration]);

	console.log(event);

	return (
		<IonModal className="eventModel ion-no-padding ion-justify-content-center" isOpen={isOpen}>
			<IonContent color="amber-50" className="ion-no-padding" fullscreen={true}>
				<IonHeader mode="md">
					<IonToolbar color="primary">
						<IonButtons slot="start">
							<IonButton onClick={dismiss}>
								<IonIcon icon={arrowBackOutline} />
							</IonButton>
						</IonButtons>
						<IonTitle size="large">Anmeldung</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonGrid className="ion-justify-content-center ion-no-padding">
					<IonRow>
						<IonCol
							className="ion-no-padding"
							sizeXs="12"
							sizeSm="8"
							sizeMd="6"
							sizeLg="7"
							sizeXl="5"
							pushSm="2"
							pushMd="3"
							pushLg="2.5"
							pushXl="3.5"
						>
							<IonLoading isOpen={isBusy} message="Anmeldung wird übermittelt" />
							{eventRegistration ? (
								<>
									<EventStartPart
										setEventRegistration={updateEventRegistration}
										eventRegistration={eventRegistration}
										event={event}
									/>
									<EventStartProfilePart
										setEventRegistration={updateEventRegistration}
										eventRegistration={eventRegistration}
										event={event}
									/>
									<EventTakePart
										setEventRegistration={updateEventRegistration}
										eventRegistration={eventRegistration}
										event={event}
									/>
									{event?.contract ? (
										<EventPricePart
											setEventRegistration={updateEventRegistration}
											eventRegistration={eventRegistration}
											event={event}
										/>
									) : (
										<></>
									)}
									<EventMailPart
										setEventRegistration={updateEventRegistration}
										eventRegistration={eventRegistration}
										event={event}
									/>

									{event?.addressRequired || event?.paymentRequired ? (
										<EventPhonePart
											setEventRegistration={updateEventRegistration}
											eventRegistration={eventRegistration}
											event={event}
										/>
									) : (
										<></>
									)}
									{event?.addressRequired || event?.paymentRequired ? (
										<EventAddressPart
											setEventRegistration={updateEventRegistration}
											eventRegistration={eventRegistration}
											event={event}
										/>
									) : (
										<></>
									)}
									{event?.ibanRequired || event?.paymentRequired ? (
										<EventIBANPart
											setEventRegistration={updateEventRegistration}
											eventRegistration={eventRegistration}
											event={event}
										/>
									) : (
										<></>
									)}
									{event?.clubRequired != ClubRequired.Ignore ? (
										<EventClubPart
											setEventRegistration={updateEventRegistration}
											eventRegistration={eventRegistration}
											event={event}
										/>
									) : (
										<></>
									)}

									{event?.contract ? (
										<EventAGBPart
											setEventRegistration={updateEventRegistration}
											eventRegistration={eventRegistration}
											event={event}
										/>
									) : (
										<></>
									)}
									<EventNotePart
										setEventRegistration={updateEventRegistration}
										eventRegistration={eventRegistration}
										event={event}
									/>
									<div className="buttomDivider"></div>
									<IonItemDivider mode="ios">Aktionen:</IonItemDivider>
									<PrivacyVerfiyEventApprovement
										checked={privacyChecked}
										setChecked={setPrivacyChecked}
									></PrivacyVerfiyEventApprovement>
									<IonButton
										size="default"
										color="primary"
										className="ion-align-self-end nextButton"
										title={i18n._("event.takePart")}
										disabled={(!canExecute && profile?.id != undefined) || !privacyChecked}
										expand="full"
										onClick={async () => {
											try {
												setIsBuy(true);
												eventRegistration.contractId = event.contractId ?? undefined;
												if (
													eventRegistration.contractId != undefined &&
													eventRegistration.additionalServiceGroups != undefined
												) {
													eventRegistration.selectedAdditionalServices = createSelectedAdditionalService(
														eventRegistration.additionalServiceGroups,
													);
												}
												if (eventRegistration.selectedAdditionalServices != undefined) {
													eventRegistration.selectedAdditionalServices = new Array<SelectedAdditionalService>();
												}

												const res = await axios.post(
													`${process.env.REACT_APP_API_ME_URL}/api/event/${event.id}/registration`,
													eventRegistration,
													{
														headers: { userId: profile?.id },
													},
												);
												if (res.status == 200) {
													console.log(res);
													await Toast.show({
														text: "Sie wurden erfolgreich angemeldet.",
														duration: "long",
														position: "center",
													});
													if (event?.tenantId && event?.id) {
														dispatch<any>(fetchEventParticipant([event.id, event?.tenantId, profile.id]));
													}

													if (!event?.eventRegistration && event?.id) {
														dispatch<any>(fetchEventRegistrationById([event.id, profile.id]));
													}
													dismiss();
												}
											} catch (error) {
												dismiss();
												presentAlert({
													header: "Fehler",
													message: "Leider ist ein Fehler bei Ihrer Anmeldung aufgetreten.",
													buttons: ["OK"],
												});
											} finally {
												setIsBuy(false);
											}
										}}
									>
										<Trans>Anmelden</Trans>
									</IonButton>
									<IonButton
										size="default"
										fill="clear"
										color="danger"
										className="ion-align-self-end"
										title={i18n._("event.takePart")}
										expand="full"
										onClick={dismiss}
									>
										<Trans>Abbrechen</Trans>
									</IonButton>
								</>
							) : (
								<>
									<IonLoading message="Ihre Anmeldung wird vorbereitet." isOpen={isOpen} />
								</>
							)}
						</IonCol>
					</IonRow>
				</IonGrid>
				<IonFooter className="ion-no-border">
					<IonItem lines="none">
						<IonAvatar slot="start">
							<IonImg src={"assets/icon/favicon-96x96.png"} />
						</IonAvatar>
						<IonRouterLink slot="start" target="_blank" href="https://www.dso-verein.de/impressum" color="medium">
							<IonLabel>Impressum</IonLabel>
							<IonLabel>Datenschutz</IonLabel>
						</IonRouterLink>
						<IonRouterLink slot="end" target="_blank" href="https://support.dso-verein.de" color="medium">
							<IonLabel>Helpcenter</IonLabel>
						</IonRouterLink>
					</IonItem>
				</IonFooter>
			</IonContent>
		</IonModal>
	);
};

export default EventParticipateStart;
