import { IonFab, IonFabButton, IonIcon } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import Page from "components/page/Page";
import TenantList from "components/tenant/TenantList";
import { add } from "ionicons/icons";
import { useState, FC } from "react";

import RegisterToTenant from "./RegisterToTenant";
import { useAppDispatch } from "app/hooks";
import { fetchProfile } from "store/profile/Profile.thunks";
import { useSelector } from "react-redux";
import { getProfile } from "store/profile/Profile.selectors";

const TenantsPage: FC = () => {
	const dispatch = useAppDispatch();
	const profile = useSelector(getProfile);

	const [isModelOpen, setisModelOpen] = useState(false);
	const onViewEnter = () => {
		dispatch<any>(fetchProfile(profile?.id));
		console.log("Enter Tenantspage");
	};

	const onViewLeave = () => {
		console.log("Leave Tenantspage");
	};

	return (
		<Page title={i18n._(t`Tenants`)} onViewEnter={onViewEnter} onViewLeave={onViewLeave}>
			<IonFab vertical="bottom" horizontal="end" slot="fixed">
				<IonFabButton onClick={() => setisModelOpen(!isModelOpen)}>
					<IonIcon icon={add} />
				</IonFabButton>
			</IonFab>
			<TenantList />

			<RegisterToTenant isOpen={isModelOpen} setIsOpen={setisModelOpen} />
		</Page>
	);
};

export default TenantsPage;
