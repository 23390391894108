import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import Page from "components/page/Page";
import List from "components/team/List";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { useEffect, FC } from "react";
import { useSelector } from "react-redux";
import { getSelectedTenant } from "store/profile/Profile.selectors";
import { fetchTeams, reloadTeams } from "store/team/Team.thunks";

export const TeamsPage: FC = () => {
	const selectedTenant = useSelector(getSelectedTenant);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch<any>(reloadTeams(newBaseListQuery()));
	}, [selectedTenant]);

	const loadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(fetchTeams(newBaseListQuery(basListQuery)));
	};
	const reloadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(reloadTeams(newBaseListQuery(basListQuery)));
	};
	return (
		<Page title={i18n._(t`My teams`)} tabBarHidden={false}>
			<List onPageScrollEnd={loadEntries} onRefresh={reloadEntries} />
		</Page>
	);
};
