import { InfiniteList } from "components/infinite/InfiniteList";
import { Tenant } from "model/Tenant";
import { FC } from "react";
import { useSelector } from "react-redux";
import { getAllTenants, getIsFetching } from "store/profile/Profile.selectors";

import Teaser from "./Teaser";

const TenantList: FC = () => {
	const tenants = useSelector(getAllTenants);
	const isFetching = useSelector(getIsFetching);

	/*
	const renderItem = (item: Address) => {
		const itemMemo = useMemo(() => <Teaser address={item} />, [item]);

		return itemMemo;
	};
	*/
	const renderItem = (item: Tenant) => <Teaser key={item.id} tenant={item} />;
	const onInfinite = async () => {
		//   if (props.addressReducer.isFetching) {
		//     return;
		//   }
		//   if (
		//     props.addressReducer.value.items.length >=
		//     props.addressReducer.value.maximumcount
		//   ) {
		//     event.target.disabled = true;
		//   } else {
		//     var query = newBaseListQuery(props.addressReducer.value);
		//     query.page++;
		//     await props.getAddresss(props.userId, query, props.tenantReducer.selected);
		//   }
		//   event.target.complete();
	};

	return (
		<>
			<InfiniteList items={tenants} isFetching={isFetching} renderItem={renderItem} onInfinite={onInfinite} />
		</>
	);
};

export default TenantList;
