import { IonAvatar, IonCard, IonChip, IonIcon, IonImg, IonItem, IonLabel } from "@ionic/react";
import { useAppDispatch } from "app/hooks";
import { callSharp, globeOutline, peopleCircleSharp } from "ionicons/icons";
import { Tenant } from "model/Tenant";
import { useEffect, useRef, useState, FC } from "react";
import { useSelector } from "react-redux";
import { getProfile, getState } from "store/profile/Profile.selectors";

import TenantHomepageSelect from "./TenantHomepageSelect";

export interface Props {
	tenant: Tenant;
}
const TenantTeaser: FC<Props> = (props: Props) => {
	const [getInfo, setGetInfo] = useState(false);
	const ionItemSlidingRef = useRef<HTMLIonItemSlidingElement>(null);
	const selectRef = useRef<HTMLIonSelectElement>(null);

	const toShare = useSelector(getState).address.toShare;

	useEffect(() => {
		if (toShare?.id === props?.tenant?.id) {
			selectRef.current?.open();
			//	setShareIsOpen(true);
		}
	}, [toShare]);

	return (
		<>
			<IonCard>
				<TenantHomepageSelect isOpen={getInfo} onDismiss={() => setGetInfo(false)} tenantId={props?.tenant?.id ?? props?.tenant?.tenantId} />

				<IonItem lines="none" onClick={() => ionItemSlidingRef.current?.open("end")}>
					<IonAvatar slot="start">
						{props?.tenant.avatarImageUrl == null || props?.tenant.avatarImageUrl == undefined || props?.tenant.avatarImageUrl == "" ? (
							<IonIcon size="large" src={peopleCircleSharp}></IonIcon>
						) : (
							<IonImg src={props?.tenant.avatarImageUrl}></IonImg>
						)}
					</IonAvatar>
					<IonLabel onClick={() => setGetInfo(true)}>
						<h1>{props?.tenant.name}</h1>
						<div>
							<IonChip outline>
								<IonIcon src={globeOutline} />
								<IonLabel>Website</IonLabel>
							</IonChip>
							<IonChip outline>
								<IonIcon src={callSharp} />
								<IonLabel>Kontakt</IonLabel>
							</IonChip>
						</div>
					</IonLabel>
				</IonItem>
			</IonCard>
		</>
	);
};

export default TenantTeaser;
