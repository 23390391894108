import "./style.scss";

import {
	IonAlert,
	IonAvatar,
	IonButton,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardSubtitle,
	IonChip,
	IonCol,
	IonContent,
	IonFooter,
	IonGrid,
	IonIcon,
	IonImg,
	IonItem,
	IonLabel,
	IonRow,
	IonSpinner,
	IonText,
	IonToolbar,
} from "@ionic/react";
import { Trans } from "@lingui/macro";
import EventParticipateStart from "components/event/participate/EventParticipateStart";
import EventParticipateOverview from "components/event/participateOverview/EventParticipateOverview";
import { format, toDate } from "date-fns-tz";
import { checkmarkCircleOutline, documentOutline, downloadOutline, eyeOutline, timerOutline } from "ionicons/icons";
import { Event } from "model/Event";
import { EventProgressType } from "model/events/EventProgressType";
import { FC, memo, useEffect, useState } from "react";

import TakePartEventButton from "./components/TakePartEventButton";
import { Toast } from "@capacitor/toast";
import { useHistory } from "react-router";
import { fetchEventById, fetchEventContractById, wirthdrawParticipation } from "store/event/Event.thunks";
import { useAppDispatch } from "app/hooks";
import { Desktop, Mobile } from "helper/UIHelper";

interface EventDetailProps {
	event: Event;
}

const EventDetail: FC<EventDetailProps> = ({ event }) => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const [showModal, setShowModal] = useState(false);
	const [showOverviewModal, setShowOverviewModal] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [showAlertConfirmWithdrawl, setShowAlertConfirmWithdrawl] = useState(false);

	useEffect(() => {
		if ((event.paymentRequired || event.contractId) && !event.contract) {
			dispatch<any>(fetchEventContractById(event.id ?? ""));
		}
	}, [event.contract]);

	const abortParticipation = (event: Event) => {
		const date = new Date(event.withDrawalDeadlineTime ?? "");
		if (date < new Date()) {
			setShowAlert(true);
			return;
		}
		setShowAlertConfirmWithdrawl(true);
		return;
	};
	return (
		<>
			{event?.eventRegistration?.eventProgressType == EventProgressType.NoMember || EventProgressType.Unknown ? (
				<EventParticipateStart event={event} dismiss={() => setShowModal(false)} isOpen={showModal} />
			) : (
				<></>
			)}
			{event?.eventRegistration?.eventProgressType != EventProgressType.NoMember || EventProgressType.Unknown ? (
				<EventParticipateOverview event={event} dismiss={() => setShowOverviewModal(false)} isOpen={showOverviewModal} />
			) : (
				<></>
			)}
			<IonGrid color="amber-50">
				<IonRow>
					<IonCol sizeXs="12" sizeSm="12" sizeMd="10" sizeLg="10" sizeXl="5" size="5" pushMd="1" pushLg="1" pushXl="0">
						{event?.eventRegistration ? isParticipatingChip(event?.eventRegistration?.eventProgressType) : <></>}

						<IonCard color="amber-50">
							{event.headerImageUrl ? (
								<IonImg src={event.headerImageUrl} className="hero-img" />
							) : (
								<IonImg src="https://public.cdn.dso-verein.de/img/stock/event/empty_event.jpeg" className="hero-img" />
							)}
							<IonItem color="amber-50" lines="full">
								<div className="ion-margin-top ion-margin-end">
									<h5 className="ion-text-wrap ion-margin-top">{event.name}</h5>
								</div>
							</IonItem>
							<IonGrid>
								<IonRow>
									<IonCol>
										<IonItem color="amber-50" mode="md" lines="full">
											<div slot="start">
												<IonChip>
													{event?.creator?.avatarImageUrl != null && event?.creator?.avatarImageUrl != "" ? (
														<IonAvatar>
															<IonImg src={event?.creator?.avatarImageUrl} />
														</IonAvatar>
													) : (
														<></>
													)}
													{event?.creator ? (
														<IonLabel className="ion-text-wrap" mode="md">
															{event?.creator?.name}
														</IonLabel>
													) : (
														<IonSpinner name="dots" />
													)}
												</IonChip>
											</div>
										</IonItem>
										<IonItem color="amber-50" mode="md" lines="full">
											<div className="ion-text-wrap">
												<IonLabel color="amber-950" slot="start">
													<h3>
														<Trans>event.date</Trans>
													</h3>
												</IonLabel>
											</div>

											<IonLabel color="amber-950" slot="end">
												{format(toDate(event.startTime!, { timeZone: "Europe/Berlin" }), "dd.MM.yyyy HH:mm", {
													timeZone: "Europe/Paris",
												})}
											</IonLabel>
										</IonItem>
										<IonItem color="amber-50" mode="md" lines="full">
											<div className="ion-text-wrap">
												<IonLabel color="amber-950" slot="start">
													<h3>
														<Trans>event.end</Trans>
													</h3>
												</IonLabel>
											</div>
											<IonLabel slot="end">
												{format(toDate(event.endTime!, { timeZone: "Europe/Berlin" }), "dd.MM.yyyy HH:mm", {
													timeZone: "Europe/Paris",
												})}
											</IonLabel>
										</IonItem>

										<IonItem color="amber-50" mode="md" lines="full">
											<div className="ion-text-wrap">
												<IonLabel color="amber-950" slot="start">
													<h3 className="ion-text-wrap">
														<Trans>event.deadline</Trans>
													</h3>
												</IonLabel>
											</div>

											<IonLabel color="amber-950" slot="end">
												{format(toDate(event.withDrawalDeadlineTime!, { timeZone: "Europe/Berlin" }), "dd.MM.yyyy HH:mm", {
													timeZone: "Europe/Paris",
												})}
											</IonLabel>
										</IonItem>
										<IonItem color="amber-50" mode="md" lines="none">
											<IonLabel color="amber-950" slot="start">
												<h3>
													<Trans>event.fee</Trans>
												</h3>
											</IonLabel>
											<IonText color="amber-950" slot="end">
												{event.paymentRequired ? "Ja" : "Nein"}
											</IonText>
										</IonItem>
									</IonCol>
								</IonRow>
							</IonGrid>
						</IonCard>
						<Desktop>
							<TakePartEventButton event={event} onClick={() => setShowModal(true)} />

							{event?.eventRegistration ? (
								<>
									<IonItem lines="none">
										<IonButton
											size="default"
											color="primary"
											expand="block"
											fill="solid"
											className="btn-take-part"
											onClick={(e) => setShowOverviewModal(true)}
										>
											<IonIcon slot="end" icon={eyeOutline} /> Anmeldung ansehen
										</IonButton>
									</IonItem>
									<IonItem lines="none">
										<IonButton
											size="default"
											mode="ios"
											slot="start"
											color="danger"
											expand="block"
											fill="clear"
											className="btn-take-part"
											onClick={(e) => abortParticipation(event)}
										>
											Teilnahme absagen
										</IonButton>
									</IonItem>
								</>
							) : (
								<></>
							)}
							{event?.eventRegistration?.confirmationUrl ? (
								<IonItem lines="none">
									<IonButton
										size="default"
										color="primary"
										expand="block"
										className="btn-take-part"
										target="_"
										href={event?.eventRegistration?.confirmationUrl}
									>
										<IonIcon slot="end" icon={downloadOutline} />
										Teilnahmebestätigung
									</IonButton>
								</IonItem>
							) : (
								<></>
							)}
						</Desktop>
					</IonCol>

					<IonCol sizeXs="12" sizeSm="12" sizeMd="12" sizeLg="12" sizeXl="7">
						<IonCard color="amber-300">
							<IonCardHeader>
								<IonCardSubtitle>Beschreibung</IonCardSubtitle>
								<h5 className="ion-text-wrap">{event.name}</h5>
							</IonCardHeader>

							<IonItem>
								<IonCardContent color="amber-50">
									<div
										dangerouslySetInnerHTML={{
											__html: event.description || "Keine Beschreibung vorhanden.",
										}}
									></div>
								</IonCardContent>
							</IonItem>
						</IonCard>
						<IonCard color="amber-300">
							<IonCardHeader>
								<IonCardSubtitle>Dokumente</IonCardSubtitle>
							</IonCardHeader>

							<IonItem>
								<IonCardContent color="amber-50">
									<IonGrid>
										<IonRow>
											{event.documents?.items?.map((e) => (
												<IonCol>
													<IonCard>
														<IonItem button={true} target="_" href={e.fileUrl} detailIcon="file">
															<IonAvatar slot="start">
																<IonIcon size="large" icon={documentOutline} />
															</IonAvatar>
															<IonLabel> {e.fileName}</IonLabel>
														</IonItem>
													</IonCard>
												</IonCol>
											))}
											{event.documents?.items?.length == 0 ? "Keine Beschreibung vorhanden." : <></>}
										</IonRow>
									</IonGrid>
								</IonCardContent>
							</IonItem>
						</IonCard>
					</IonCol>
				</IonRow>
			</IonGrid>
			<IonAlert
				isOpen={showAlert}
				onDidDismiss={() => setShowAlert(false)}
				header="Teilnahme stornieren"
				subHeader="Frist abgelaufen"
				message="Leider ist die Frist zur kostenlosen Stornierung bereits abgelaufen. Kontaktiere den Veranstalter um deine Anmeldung zu stornieren."
				buttons={[
					{
						text: "Kontakt",
						role: "confirm",
						handler: () => {
							window.location.href = "mailto:";
							setShowAlert(false);
						},
					},
					{
						text: "Zurück",
						role: "cancel",
						handler: () => {
							setShowAlert(false);
						},
					},
				]}
			/>
			<IonAlert
				isOpen={showAlertConfirmWithdrawl}
				onDidDismiss={() => setShowAlertConfirmWithdrawl(false)}
				header="Teilnahme stornieren"
				subHeader="Kostenlose Stornierung"
				message="Sie können bis zum Ende der Kündigungsfrist kostenlos stornieren."
				buttons={[
					{
						text: "Stornieren",
						role: "confirm",
						handler: async () => {
							if (event?.id && event.eventRegistration?.personId) {
								dispatch<any>(wirthdrawParticipation([event.eventRegistration?.personId, event?.id]));
								setShowAlertConfirmWithdrawl(false);
								// Show toast that everything worked fine
								await Toast.show({
									text: "Sie wurden von der Veranstaltung abgemeldet.",
									duration: "long",
									position: "center",
								}); //Wait 2 Seconds to show the user the toast
								await new Promise((resolve) => setTimeout(resolve, 200));
								if (event.id) {
									dispatch<any>(fetchEventById(event?.id));
								}

								// Go back to the event page list
								history.goBack();
							} else {
								await Toast.show({
									text: "Sie konnten nicht abgemeldet werden. Bitte melden Sie sich beim Veranstalter.",
									duration: "long",
									position: "center",
								});
							}
						},
					},
					{
						text: "Abbrechen",
						role: "cancel",
						handler: () => {
							setShowAlertConfirmWithdrawl(false);
						},
					},
				]}
			/>
			<Mobile>
				<IonFooter color="amber-50">
					<IonToolbar color="amber-50" className="ion-padding-bottom ">
						<IonCardSubtitle className="ion-padding-start ion-margin-top">Aktionen:</IonCardSubtitle>
						<TakePartEventButton event={event} onClick={() => setShowModal(true)} />

						{event?.eventRegistration ? (
							<>
								<IonGrid>
									<IonRow>
										<IonCol>
											<IonButton
												size="default"
												color="primary"
												expand="block"
												fill="solid"
												className="btn-take-part"
												onClick={(e) => setShowOverviewModal(true)}
											>
												<IonIcon slot="end" icon={eyeOutline} /> Anmeldung ansehen
											</IonButton>
										</IonCol>
									</IonRow>
									<IonRow>
										<IonCol>
											<IonButton
												size="default"
												mode="ios"
												color="danger"
												expand="block"
												fill="clear"
												className="btn-take-part"
												onClick={(e) => abortParticipation(event)}
											>
												Teilnahme absagen
											</IonButton>
										</IonCol>
									</IonRow>
								</IonGrid>
							</>
						) : (
							<></>
						)}
						{event?.eventRegistration?.confirmationUrl ? (
							<IonButton
								size="default"
								color="primary"
								expand="block"
								className="btn-take-part"
								target="_"
								href={event?.eventRegistration?.confirmationUrl}
							>
								<IonIcon slot="end" icon={downloadOutline} />
								Teilnahmebestätigung
							</IonButton>
						) : (
							<></>
						)}
					</IonToolbar>
				</IonFooter>
			</Mobile>
		</>
	);
};

export default memo(EventDetail);

const takePartButtonText = (eventProgressType?: EventProgressType) => {
	switch (eventProgressType) {
		case EventProgressType.Unknown:
			return <IonSpinner name="dots" />;
		case EventProgressType.NoMember:
			return <Trans>event.takePart</Trans>;
		case EventProgressType.Waiting:
			return <Trans>event.alreadyTakePart</Trans>;
		case EventProgressType.Participating:
			return <Trans>event.alreadyTakePart</Trans>;
		case EventProgressType.Invited:
			return <Trans>event.takePart</Trans>;
		default:
			break;
	}
	return <IonSpinner name="dots" />;
};
const isParticipatingChip = (eventProgressType?: EventProgressType) => {
	switch (eventProgressType) {
		case EventProgressType.Unknown:
			return <></>;
		case EventProgressType.NoMember:
			return <></>;
		case EventProgressType.Waiting:
			return (
				<IonChip color="warning" outline={false}>
					<IonIcon color="warning" icon={timerOutline}></IonIcon>
					<IonLabel>Angemeldet: Warteliste</IonLabel>
				</IonChip>
			);
		case EventProgressType.Participating:
			return (
				<IonChip color="success" outline={false}>
					<IonIcon color="success" icon={checkmarkCircleOutline}></IonIcon>
					<IonLabel>Angemeldet: Teilnehmer</IonLabel>
				</IonChip>
			);
		case EventProgressType.Invited:
			return <></>;
		default:
			break;
	}
	return (
		<IonChip color="medium" outline={false}>
			<IonSpinner name="dots" />
		</IonChip>
	);
};
const validateTakePartPossible = (event: Event) => {
	if (!event) {
		console.log("no event loaded");
		return false;
	}
	// Eventuell wurde durch einen Admin die Teilnahme geschlossen
	if (event.closed) {
		console.log("Event closed");
		return false;
	}
	// Check noch Platz vorhanden
	if (event.participantCount ?? 0 >= (event.maxParticipating ?? 0)) {
		if (event.waitingCount ?? 0 >= (event.maxWaiting ?? 0)) {
			if (event.waitingCount == event.maxWaiting) {
				if (event.waitingCount ?? 0 > (event.maxWaiting ?? 1)) {
					return false;
				}
				return false;
				console.log("No Space in event");
			}
		}
	}
	// Anmeldefrist checken
	if (event.startTime > new Date()) {
		console.log("startime over");
		return false;
	}

	if (event.eventProgressType == EventProgressType.Participating || event.eventProgressType == EventProgressType.Waiting) {
		console.log("progress type is not nomember");
		return false;
	}
	return true;
};
