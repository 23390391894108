import { IonButton, IonButtons, IonItem, IonModal } from "@ionic/react";
import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import LogList from "components/logElement/List";
import Page from "components/page/Page";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { newBaseListTenantQuery } from "model/query/BaseListTenantQuery";
import { useEffect, useState, VFC } from "react";
import { useSelector } from "react-redux";
import { reloadLogElements, fetchLogElements } from "store/log/Log.thunks";
import { getSelectedTenant } from "store/profile/Profile.selectors";
import LogPageModal from "./LogPageModal";
import DeleteAccountModal from "./DeleteAccountModal";

const LogPage: VFC = () => {
	const selectedTenant = useSelector(getSelectedTenant);
	const dispatch = useAppDispatch();
	const [modalOpen, setModalOpen] = useState(false);
	const [modalDeleteOpen, setModalDeleteOpen] = useState(false);

	useEffect(() => {
		dispatch<any>(reloadLogElements(newBaseListTenantQuery(newBaseListQuery(), selectedTenant)));
	}, [selectedTenant]);

	const loadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(fetchLogElements(newBaseListTenantQuery(newBaseListQuery(basListQuery), selectedTenant)));
	};
	const reloadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(reloadLogElements(newBaseListTenantQuery(newBaseListQuery(basListQuery), selectedTenant)));
	};

	return (
		<Page title={i18n._(t`My logs`)} tabBarHidden={false}>
			<LogPageModal isOpen={modalOpen} onCancel={() => setModalOpen(false)} />
			<DeleteAccountModal isOpen={modalDeleteOpen} onCancel={() => setModalDeleteOpen(false)} />

			<IonItem lines="none">
				<IonButtons slot="end">
					<IonButton onClick={(e) => setModalOpen(true)}>DSGVO Export anfordern</IonButton>
					<IonButton color="danger" onClick={(e) => setModalDeleteOpen(true)}>
						Account löschen
					</IonButton>
				</IonButtons>
			</IonItem>
			<LogList onPageScrollEnd={loadEntries} onRefresh={reloadEntries} />
		</Page>
	);
};

export default LogPage;
