import axios from "axios";
import { BaseList } from "model/BaseList";
import { BaseListQuery, BaseListQueryInstance } from "model/BaseListQuery";
import { Invoice } from "model/Invoice";
import { PersonTenant } from "model/person/PersonTenant";
import { Tenant } from "model/Tenant";

class TenantService {
	async getList(query: BaseListQueryInstance, userId: string, textSearch: string): Promise<BaseList<Tenant>> {
		const params = new URLSearchParams();

		params.append("userid", userId);
		query.toEntries().forEach(([key, value]) => {
			params.append(key, value as string);
		});
		const res = await axios.get(`${process.env.REACT_APP_API_ME_URL}/api/me/association`, { params });
		return res.data;
	}
	async searchTenants(query: BaseListQueryInstance, userId: string): Promise<BaseList<PersonTenant>> {
		const params = new URLSearchParams();
		params.append("userid", userId);
		query.toEntries().forEach(([key, value]) => {
			params.append(key, value as string);
		});
		const res = await axios.get(`${process.env.REACT_APP_API_ME_URL}/api/me/association/find`, { params, headers: { userId: userId } });
		return res.data;
	}
}
const tenantService = new TenantService();
export default tenantService;
