import { IonButton, IonFooter, IonItem, IonToolbar } from "@ionic/react";
import { FC } from "react";

interface CloseFooterProps {
	onClick: () => void;
}

export const CloseFooter: FC<CloseFooterProps> = ({ onClick }) => {
	return (
		<IonFooter style={{ background: "var(--ion-color-amber-50)" }} color="amber-50">
			<IonToolbar color="amber-50" className="ion-padding-bottom ion-margin-top">
				<IonButton
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						onClick();
					}}
					form="round"
					expand="block"
					color="amber-400"
					className="ion-no-border ion-padding-end ion-padding-start ion-margin"
				>
					Schließen
				</IonButton>
			</IonToolbar>
		</IonFooter>
	);
};
