import { Theme } from "./Theme";

export interface Tenant {
	id: string;
	tenantId?: string; // used if coming from search
	name: string;
	primaryUrl?: string;
	personAvatarImageUrl?: string;
	avatarImageUrl?: string;
	personExternAvatarImageUrl?: string;
	personInternAvatarImageUrl?: string;
	themeInfos?: Array<Theme>;
}

export function newTenant(): Tenant {
	return {
		id: "",
		name: "",
	};
}
