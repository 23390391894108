import { i18n } from "@lingui/core";
import { t } from "@lingui/macro";
import { useAppDispatch } from "app/hooks";
import List from "components/honor/List";
import Page from "components/page/Page";
import { BaseListQuery, newBaseListQuery } from "model/BaseListQuery";
import { newBaseListTenantQuery } from "model/query/BaseListTenantQuery";
import { useEffect, VFC } from "react";
import { useSelector } from "react-redux";
import { fetchHonors, reloadHonors } from "store/honor/Honor.thunks";
import { getSelectedTenant } from "store/profile/Profile.selectors";

export const HonorsPage: VFC = () => {
	const selectedTenant = useSelector(getSelectedTenant);
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch<any>(reloadHonors(newBaseListQuery()));
	}, [selectedTenant]);

	const loadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(fetchHonors(newBaseListQuery(basListQuery)));
	};
	const reloadEntries = async (basListQuery: BaseListQuery): Promise<void> => {
		dispatch<any>(reloadHonors(newBaseListQuery(basListQuery)));
	};
	return (
		<Page title={i18n._(t`My honors`)} tabBarHidden={false}>
			<List onPageScrollEnd={loadEntries} onRefresh={reloadEntries} />
		</Page>
	);
};
